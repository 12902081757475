import { SortDirection } from '@angular/material/sort';
import { TiaTimelineEvent } from '@sealights/sl-shared-contracts';
import { Subject } from 'rxjs';

import { PagedApiRequestParams, PagedListApiRequestParams, PagedResponse, PagedResponseWrapper } from '@@core/models/api.model';
import { TestResult } from '@@core/models/test.model';
import { Period, PopularPeriod } from '@@core/models/utils/popular-period.model';
import { FilterItem } from '@@shared/filter-dropdown';

import { TiaChartLegendItem } from './tia-chart.model';
import { TiaGenericState } from './tia-generic-store.model';
import { TiaV3FiltersState } from './tia-v3-filters-store.model';

export const ONE_MINUTE = 60 * 1000;
export const ONE_DAY = 24 * 60 * ONE_MINUTE;
export const ACTUAL_TIA_RESULTS_COLOR = '#79A7D1';
export const Potential_TIA_RESULTS_COLOR = 'var(--sl-secondary-brand-yellow-main)';
export const SL_DEFAULT_TEST_GROUP = '_sl_default_test_group_';

export const DEFAULTS: { [key: string]: string; } = {
	[SL_DEFAULT_TEST_GROUP]: 'Default'
};

export enum TiaEnablement {
	None = 'none',
	Partial = 'partial',
	Full = 'full',
}

export type TiaFullRunType =
	| 'unsupported'
	| 'testSelectionOff'
	| 'scheduledFullRun'
	| 'recommendationsError'
	| 'recommendationsNotReadyOnTime'
	| 'recommendationsNotAvailable'
	| 'testSelectionDisabledByAgent'
	| 'noSkippedTestsByTia'
	| 'userScheduledFullRun';
export type BuildRecommendationsStatusType = 'notReady' | 'noHistory' | 'ready' | 'error';
export enum FullRunReason {
	Unsupported = 'unsupported',
	NoHistory = 'noHistory',
	ScheduledFullRun = 'scheduledFullRun',
	UserScheduledFullRun = 'userScheduledFullRun',
	RecommendationsNotAvailable = 'recommendationsNotAvailable',
	RecommendationsNotReadyOnTime = 'recommendationsNotReadyOnTime',
	RecommendationsError = 'recommendationsError',
	NoSkippedTestsByTia = 'noSkippedTestsByTia',
	TestSelectionOff = 'testSelectionOff',
	TestSelectionDisabledByAgent = 'testSelectionDisabledByAgent',
}
export const tiaFullRunDescription = new Map<TiaFullRunType | BuildRecommendationsStatusType, string>([
	[FullRunReason.Unsupported, 'The testing framework used is not supported. Please contact support to learn what can be done'],
	[FullRunReason.NoHistory, 'TIA recommended a full test run because no previous data was available to calculate recommendations'],
	[FullRunReason.ScheduledFullRun, 'TIA recommended a full test run according to the policy'],
	[FullRunReason.UserScheduledFullRun, 'TIA recommended a full test run based on a manual request'],
	[
		FullRunReason.RecommendationsNotAvailable,
		'TIA recommendations were not ready due to missing prerequisites. Please contact support to learn what can be done',
	],
	[
		FullRunReason.RecommendationsNotReadyOnTime,
		'TIA recommendations were not ready on time so a full run was recommended. Please contact support to learn what can be done',
	],
	[
		FullRunReason.RecommendationsError,
		'an error was report by the agent while running the tests that resulted in a full run. Please contact support to learn what can be done',
	],
	[
		FullRunReason.NoSkippedTestsByTia,
		'A full run was recommended as no tests should be skipped. View the "Tests" / "Recommendations" tab to learn why all tests were recommended to run',
	],
	[FullRunReason.TestSelectionOff, 'Test Selection was off for this run'],
	[
		FullRunReason.TestSelectionDisabledByAgent,
		'Test selection is disabled by the agent, so a full run was initiated. Please contact support to learn what can be done',
	],
]);
export const tiaFullRunTitle = new Map<FullRunReason | BuildRecommendationsStatusType, string>([
	[FullRunReason.Unsupported, 'unsupported framework'],
	[FullRunReason.NoHistory, 'no history'],
	[FullRunReason.ScheduledFullRun, 'policy'],
	[FullRunReason.UserScheduledFullRun, 'user'],
	[FullRunReason.RecommendationsNotAvailable, 'missing prerequisites'],
	[FullRunReason.RecommendationsNotReadyOnTime, 'timeout'],
	[FullRunReason.RecommendationsError, 'error'],
	[FullRunReason.NoSkippedTestsByTia, 'recommended'],
	[FullRunReason.TestSelectionOff, 'test selection off'],
	[FullRunReason.TestSelectionDisabledByAgent, 'agent'],
]);

export enum TiaTestRecommendationFacet {
	all = 'All',
	pinned = 'Pinned tests',
	dependent = 'Dependent tests',
	recentlyFailed = 'Previously failed',
	impacted = 'Impacted tests',
	previouslyRecommendedAndNotExecuted = 'Recommendations from previous builds',
	testAdded = 'New test',
	unimpacted = 'Unimpacted test'
}
export type TiaTestRecommendationReasonType = keyof typeof TiaTestRecommendationFacet;

export const popularTiaPeriods = (): PopularPeriod[] => {
	const startOfToday = new Date().setHours(0, 0, 0, 0);
	const endOfToday = startOfToday + ONE_DAY - 1;

	return [
		{
			displayName: 'Last 7 days',
			name: 'Last 7 days',
			period: {
				from: startOfToday - 7 * ONE_DAY,
				to: endOfToday,
			},
			isCustom: false,
			isBuiltIn: true,
		},
		{
			displayName: 'Last 14 days',
			name: 'Last 14 days',
			period: {
				from: startOfToday - 14 * ONE_DAY,
				to: endOfToday,
			},
			isCustom: false,
			isBuiltIn: true,
		},
		{
			displayName: 'Last 30 days',
			name: 'Last 30 days',
			period: {
				from: startOfToday - 30 * ONE_DAY,
				to: endOfToday,
			},
			isCustom: false,
			isBuiltIn: true,
		},
		{
			displayName: 'Last 60 days',
			name: 'Last 60 days',
			period: {
				from: startOfToday - 60 * ONE_DAY,
				to: endOfToday,
			},
			isCustom: false,
			isBuiltIn: true,
		},
		{
			displayName: 'Last 90 days',
			name: 'Last 90 days',
			period: {
				from: startOfToday - 90 * ONE_DAY,
				to: endOfToday,
			},
			isCustom: false,
			isBuiltIn: true,
		},
		{
			displayName: 'Custom...',
			name: 'Custom...',
			period: {
				from: 0,
				to: 0,
			},
			isCustom: true,
			isBuiltIn: false,
		},
	];
};

export interface TiaOverviewFilterState {
	period?: PopularPeriod;
	appName?: string;
}

export interface TiaSettingsRequestParams extends PagedApiRequestParams {
	appName: string;
	testStage: string;
	tiaOn?: boolean;
}

export interface TiaOverviewRequestBody extends PagedListApiRequestParams {
	appNames?: string[];
	testStages?: string[];
	periodFrom: number;
	periodTo: number;
	tiaEnabled?: number[];
}

export interface TiaOverviewRequestQuery extends PagedApiRequestParams {
	sortBy: TiaOverviewSortableFields;
}

export interface TiaOverviewRequestParams {
	body: TiaOverviewRequestBody;
	query: TiaOverviewRequestQuery;
}
export interface TiaFilterTestStageItem {
	name: string;
	duration: number;
}

export type TiaFilterTestStageResponse = PagedResponseWrapper<TiaFilterTestStageItem, PagedResponse<TiaFilterTestStageItem>>;

export interface TiaV2TestStage {
	testStage: string;
}

export type TiaV2TestStageResponse = PagedResponseWrapper<TiaV2TestStage, PagedResponse<TiaV2TestStage>>;

export interface TestStageRunCount {
	fullRun: number;
	tiaRun: number;
}

export interface TestStageDurationMetrics {
	actual: number;
	simulation: number;
	reduced: number;
	recommended: number;
	simulatedReduced: number;
}

export interface TestStageTestCountMetrics {
	actual: number;
	simulation: number;
	recommended: number;
	reduced?: number; // only in v3/execution-stats/test-counts
}

export interface TiaTestStageMetrics {
	appName: string;
	name: string;
	tiaEnabled: boolean;
	tiaUnsupported: boolean;
	percentageTimeReduced: PercentageTimeReduced;
	count: TestStageRunCount;
	avgTests: TestStageTestCountMetrics;
	duration: TestStageDurationMetrics;
	avgTime: TestStageDurationMetrics;
	testCount?: TestStageTestCountMetrics; // only in v3/execution-stats/test-counts
	percentageTestsReduced?: PercentageTimeReduced; // only in v3/execution-stats/test-counts
	// custom added for specific case
	outsideOfPeriodRange?: boolean;
}

export interface Application {
	id?: string;
	name: string;
}

export interface AppLastTestStage {
	appName: string;
	testStage?: string;
	generated?: number;
	notFound?: boolean;
}

export interface DurationTestStages {
	actual: number;
	potential?: number;
	stageExecution?: number;
	simulatedReduced: number;
	reduced: number;
}

export interface PercentageTimeReduced {
	actual: number;
	simulation: number;
	potential: number;
}

export interface MaxDurationAllStages {
	actual: number;
}

export type TiaAppMetricsResponse = PagedResponseWrapper<
	TiaAppMetrics,
	PagedResponse<TiaAppMetrics> & {
		maxDurationAllStages: MaxDurationAllStages;
		percentageTimeReduced: PercentageTimeReduced;
		notFoundAppsLastTestStages?: AppLastTestStage[];
	}>;

export interface TiaAppMetrics {
	appName: string;
	durationAllStages: DurationTestStages;
	percentageTimeReduced: PercentageTimeReduced;
	testStages: TiaTestStageMetrics[];
	tiaEnablement?: TiaEnablement;
}

export interface TiaBarSegment {
	value: number;
	name: string;
	color: string;
	actualDuration: number;
	simulatedDuration: number;
	tiaEnabled: boolean;
}

export interface TiaBar {
	total: number;
	segments: TiaBarSegment[];
	length?: string;
}

export interface TiaAppRow {
	timeReducedAvg: number;
	simulatedTimeReducedAvg: number;
	simulatedSavings: number;
	savings: number;
	appName: string;
	bars: {
		actual: TiaBar;
		simulated: TiaBar;
	};
	tiaEnablement?: TiaEnablement;
	testStages: TiaTestStageMetrics[];
	metadata?: AppLastTestStage;
	tiaUnsupported: boolean;
}

export interface TiaAppTestStage {
	appName: string;
	testStage: string;
}

export interface TiaDetailsContext extends TiaAppTestStage {
	period: PopularPeriod;
	branchName?: string;
	bsid?: string;
}

export interface TiaTestStagesGetRequestParams extends PagedApiRequestParams {
	period: Period;
	appName?: string;
}

export interface TiaTestStagesPostRequestParams extends PagedApiRequestParams {
	period: Period;
	appNames?: string[];
}

export interface TiaDetailsRequestParams extends PagedApiRequestParams {
	appName?: string;
	testStage?: string;
	branchName?: string;
	bsid?: string;
}
export interface TiaDetailsRequestManualTestParams extends PagedApiRequestParams, Partial<TiaDetailsContext> { }

export interface TiaAppTestStageRequestParams extends PagedApiRequestParams, TiaAppTestStage { }

export interface TiaBuildsRequestParams extends PagedApiRequestParams {
	appName: string;
	testStage: string;
	branchName?: string;
	testGroupId?: string;
	queryOutsideOfDateRange?: boolean;
	from: number;
	to: number;
	buildFilterOption?: TiaTimelineFilterOption;
	mode?: BuildTsrMode;
}

export enum TiaTimelineFilterOption {
	All = 'all',
	WithRun = 'withRun',
}

export enum BuildTsrMode {
	Build = 'build',
	TSR = 'tsr'
}

export interface TiaBuildRangeRequestParams extends PagedApiRequestParams, TiaAppTestStage {
	period: Period;
	branchName?: string;
}

export interface TiaBuildRecommendationRequestParams {
	bsId: string;
	testStage: string;
	baselineBsId: string;
}

export interface TiaBuildTestCount {
	actual: number;
	skippedByCustomer: number;
	skippedByTia?: number; //  required if tiaEnabled true
	simulation?: number; //  required if tiaEnabled false (equals simulation if exists - otherwise recommended)
}

export interface TiaBuildFullRun {
	executed: boolean;
	recommended: boolean;
	reason?: FullRunReason;
}

export interface TiaBuildModules {
	numberOfModules: number;
	numberOfModulesReadyOnTime: number;
}

export enum TiaExecutedBy {
	Api = 'api',
	Agent = 'agent',
}

export enum MessageSeverity {
	Info = 'info',
	Debug = 'debug',
	Warning = 'warn',
	Error = 'error'
}

export interface TiaAppBuild {
	buildName: string;
	branchName: string;
	bsid: string;
	generated: number;
	tiaEnabled?: boolean;
	tiaExecutedBy: TiaExecutedBy;
	status: BuildRecommendationsStatusType;
	testCount?: TiaBuildTestCount;
	fullRun: TiaBuildFullRun;
	modules: TiaBuildModules;
	tiaUnsupported: boolean;
	summaryInsights?: TiaBuildInsights;
	buildVersion: number;
	ranOnTestStage: boolean;
	isFirstBuild: boolean;
	recommendationsCalculatedByApi: boolean;
	modifications?: number;
	tsrName?: string;
	messages?: {
		severity: MessageSeverity;
		message: string;
	}[];
}

export type TiaAppBuildResponse = PagedResponseWrapper<TiaAppBuild, PagedResponse<TiaAppBuild>>;

export interface TiaBuildInsights {
	isFormatCorrectedBySL: boolean;
	isTestEventsReported: boolean;
	isPartialSupported: boolean;
}

export interface TiaBuildRange {
	from: string;
	to: string;
	count: number;
}

export interface TiaTestSelectionRequestParams {
	appName: string;
	testStage: string;
}

export interface TiaTestSelectionDefaultStatusParams {
	appName: string;
	testStage: string;
	enabled?: boolean;
}

export interface TiaTestSelection {
	enabled: boolean;
	isDefined: boolean;
	testStageDefaultStatus: boolean;
}

/**
 * Build Tab (common to executed tests, recommended tests, code to test map, modules)
 */

export interface TiaBuildChartConfig {
	allCount: number;
	series: number[];
	labels: string[];
	colors: string[];
	legendItems: TiaChartLegendItem[];
}

export type TiaTestType = 'regular' | 'parameterized';

/**
 * All Tests (merged executions and recommendations)
 */

export type TiaTestExecutionStatus =
	| 'all'
	| 'executedFromTiaRecommendations'
	| 'executedRecommendationsNotReadyOnTime'
	| 'executedButRecommendedToSkip'
	| 'skippedFromRecommendations'
	| 'skippedByUser'
	| 'executedFromFullRunPolicy'
	| 'error'
	| 'skippedButFullRunRecommended';

export const TiaOnTestExecutionStatusNames = new Map<TiaTestExecutionStatus, string>([
	['executedFromTiaRecommendations', 'Ran according to recommendation'],
	['executedRecommendationsNotReadyOnTime', 'Ran, TIA recommendation was not ready in time'],
	['executedButRecommendedToSkip', 'Ran, though it was recommended to be skipped'],
	['executedFromFullRunPolicy', 'Ran as part of a full run according to the TIA policy'],
	['skippedFromRecommendations', 'Skipped according to recommendation'],
	['skippedByUser', 'Skipped, though it was recommended to be run'],
	['skippedButFullRunRecommended', 'Skipped, though it was recommended to be run as part of a full run according to the TIA policy'],
	['error', 'Unknown status']
]);

export const TiaOffTestExecutionStatusNames = new Map<TiaTestExecutionStatus, string>([
	['executedFromTiaRecommendations', 'Ran, TIA would have recommended it to run'],
	['executedRecommendationsNotReadyOnTime', 'Ran, TIA recommendation was not ready in time'],
	['executedButRecommendedToSkip', 'Ran, though TIA would have recommended it to be skipped'],
	['skippedFromRecommendations', 'Skipped, TIA would have recommended it to be skipped'],
	['skippedByUser', 'Skipped, though TIA would have recommended it to be run'],
	['error', 'Unknown status']
]);

export interface TiaBuildTestsRequestParams extends TiaDetailsRequestParams {
	result?: TestResult;
	filterBy?: TiaTestExecutionStatus;
}

export interface TiaBuildTest {
	testName: string;
	testResult: TiaExecutedTestResults;
	testExecutionStatus: TiaTestExecutionStatus;
	recommendationReasons: TiaTestRecommendationFacet[];
	executionNumber: number;
	pinnedBy?: string;
	pinnedDate?: string;
	pinnedReason?: string;
}

export enum BuildTestsStatus {
	OK = 'OK',
	InProgress = 'InProgress',
	Error = 'Error',
	WillNeverCalculate = 'WillNeverCalculate',
}

export type BuildTestsResponse = PagedResponseWrapper<TiaBuildTest, PagedResponse<TiaBuildTest> & { status?: BuildTestsStatus; testSelectionStatus?: boolean; }>;

/**
 * Executed Tests
 */

export interface TiaExecutedTestResults {
	passed: number;
	failed: number;
	skipped?: number;
}


/**
 * Recommended Tests
 */

export interface TiaTestRecommendationReasonCount {
	name: string;
	displayName?: string;
	showLabel?: boolean;
	count: number;
}

export type TiaTestRecommendationReasonCountResponse = PagedResponseWrapper<TiaTestRecommendationReasonCount, PagedResponse<TiaTestRecommendationReasonCount>>;

export interface TiaRecommendedTestsRequestParams extends TiaDetailsRequestParams {
	reason?: TiaTestRecommendationReasonType;
}

export interface TiaRecommendedTest {
	testName: string;
	reasons: string[];
	displayedReasons?: string[];
	testType: TiaTestType;
	numberOfPermutations?: number;
	relativeBuild: {
		bsId: string;
		buildName: string;
		timestamp: number;
	};
	pinnedBy?: string;
	pinnedDate?: string;
	pinnedReason?: string;
}
export type TiaRecommendedTestResponse = PagedResponseWrapper<TiaRecommendedTest, PagedResponse<TiaRecommendedTest>>;

/**
 * Impacted Tests
 */

export interface TiaImpactedTestsRequestParams extends TiaDetailsRequestParams {
	withTests?: boolean;
	useUserMappedData?: boolean;
	testGroupId: string;
}

export interface TiaMethodImpactedTests {
	name: string;
	line: number;
	impactedTests: string[];
}

export interface TiaFileImpactedTests {
	name: string;
	methods: TiaMethodImpactedTests[];
}

export interface TiaAppFileImpactedTests {
	appName: string;
	file: TiaFileImpactedTests;
}

export type TiaAppFileImpactedTestsResponse = PagedResponseWrapper<TiaAppFileImpactedTests, ImpactedTestsResponse>;

export interface TiaAppAllImpactedTests {
	appName: string;
	impactedTests: string[];
}

export type TiaAppImpactedTestsRow = TiaAppFileImpactedTests | TiaAppAllImpactedTests;

/**
 * Modules
 */

export interface TiaModulesRequestParams extends PagedApiRequestParams {
	bsid: string;
	testStage: string;
	hadRecommendationsOnTime?: boolean;
}

export interface TiaModulesTabConfig {
	allCount: number;
	series: number[];
	labels: string[];
	colors: string[];
	legendItems: TiaChartLegendItem[];
	filterItems: FilterItem[];
}

export interface TiaBuildModule {
	uniqueModuleId: string;
	runDuration: number;
	savedTests: number;
	additionalWaitingTime: number;
	hadRecommendationsOnTime: boolean;
	recommendedToSkip: number;
	agentWaitingTime: number;
}

export type TiaBuildModuleResponse = PagedResponseWrapper<TiaBuildModule, PagedResponse<TiaBuildModule>>;

export type TiaModuleFilterId = 'all' | 'withRecommendations' | 'withoutRecommendations';

/**
 * Statistics
 */

export interface TiaExecutionStats {
	executions: number;
	tests: {
		passed: number;
		failed: number;
		skipped: number;
	};
}

export interface TiaBuildTestMetricsRequestParams extends PagedApiRequestParams, TiaV3FiltersState { }

export type TiaBuildTestMetricsResponse = PagedResponseWrapper<TiaBuildTestMetrics, PagedResponse<TiaBuildTestMetrics>>;

export interface TestHistoryMetrics {
	actual: number;
	recommended: number;
	simulation: number;
	skippedByTia: number;
	skippedByCustomer: number;
}

export interface TiaBuildTestMetrics {
	buildName: string;
	generated: number;
	bsid: string;
	testSelectionEnabled: boolean;
	testCount: TestHistoryMetrics;
	testTime: TestHistoryMetrics;
	tsrId?: string;
}

export interface TiaSettingsDialogData {
	context: Partial<TiaV3FiltersState>;
	testMetrics?: TiaTestStageMetrics;
	autoOpened: boolean;
	genericStore: TiaGenericState;
	updateStore: Subject<Partial<TiaGenericState>>;
}

export interface TestCountsForBuildValues {
	unique: number;
	total: number;
}

export interface TestCountsForBuild {
	all: TestCountsForBuildValues;
	failed: TestCountsForBuildValues;
	passed: TestCountsForBuildValues;
}

export enum TiaOverviewSortableFields {
	tia = 'tiaEnabled',
	app = 'appName',
	testExecution = 'durationActualAllStages',
}

export enum TiaImpactedTestsSortableFields {
	withTests = 'withTests',
}

export enum ImpactedTestsStatus {
	BuildFailed = 'BuildFailed',
	BuildInProgress = 'BuildInProgress',
	PtcModelNotFound = 'PtcModelNotFound',
	NoCodeChanges = 'NoCodeChanges',
	OutsideCalculationLimits = 'OutsideCalculationLimits',
	Ok = 'Ok',
	InProgress = 'InProgress',
	NoCodeToTestStatusInformation = 'NoCodeToTestStatusInformation',
}
export enum ImpactedTestsErrorMessages {
	BuildFailed = 'Please contact customer support (errorId: BuildFailed)',
	PtcModelNotFound = 'Please contact customer support (errorId: PtcModelNotFound)',
	OutsideCalculationLimits = 'Too many changes. Code-to-test is limited to no more than 1,000 files',
}
export const IMPACTED_TESTS_STATUS_TYPE_SUCCESS: ReadonlyArray<ImpactedTestsStatus> = [ImpactedTestsStatus.Ok, ImpactedTestsStatus.NoCodeChanges];

export type ImpactedTestsResponse = PagedResponse<TiaAppFileImpactedTests> & { status: ImpactedTestsStatus; hasUserTestMapping: boolean; };

export interface TiaSummaryFilters {
	appName: string;
	periodFrom: number;
	periodTo: number;
}

export const TiaEnablementText = new Map<TiaEnablement, string>([
	[TiaEnablement.None, 'Off'],
	[TiaEnablement.Partial, 'Partially on'],
	[TiaEnablement.Full, 'On'],
]);

export interface TiaAppTestStageGetRequestParams {
	appName: string;
	branchName?: string;
	page: number;
	pageSize: number;
}

export interface TiaAppTestStageGet {
	appName: string;
	branchName: string;
	testStage: string;
}

export type TiaAppTestStageGetResponse = PagedResponseWrapper<TiaAppTestStageGet, PagedResponse<TiaAppTestStageGet>>;

export interface TiaPreviousBuild {
	appName: string;
	branchName: string;
	buildName: string;
	generated: number;
	endOfStageDate: string;
	bsId: string;
	labIds: [
		{
			labId: string;
		}
	];
}

export interface TiaTestStagePreviousBuildRequestParams {
	testStage: string;
	bsId: string;
	branchName?: string;
	page: number;
	pageSize: number;
}

export type TiaTestStagePreviousBuildResponse = PagedResponseWrapper<TiaPreviousBuild, PagedResponse<TiaPreviousBuild>>;

export interface TiaStatisticalModelBuild {
	appName: string;
	branchName: string;
	buildName: string;
	bsId: string;
	generated: number;
}

export interface TiaChangesFromBuild extends TiaStatisticalModelBuild {
	recalculated?: boolean;
}

export interface TiaRecommendationsMetadata {
	isTriggeredByApi?: boolean;
	changesFromBuild?: TiaChangesFromBuild;
	statisticalModelBuild?: TiaStatisticalModelBuild;
	status?: 'InProgress' | 'Done' | undefined;
}

export interface TiaSummaryTableFilters {
	sortBy: string;
	sortDirection: SortDirection;
	page: number;
	pageSize: number;
}

export enum CodeToTestFilterOptions {
	ALL = 'all',
	WithTests = 'withTests'
}

export const CUSTOM_TIA_PERIOD_NAME = 'Custom...';

export type TiaTimelineResponse = PagedResponseWrapper<TiaTimelineEvent, PagedResponse<TiaTimelineEvent>>;
export type TiaBuildMetricsV3Response = PagedResponseWrapper<TiaAppBuild, PagedResponse<TiaAppBuild>>;

export type TiaBranchNamesResponse = PagedResponseWrapper<string, PagedResponse<string>>;
